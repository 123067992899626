<template>
	<v-col cols="12" class="d-flex flex-column align-self-stretch pa-0">
		<v-card flat tile :loading="valuesLoading">
			<v-row class="pa-11 pb-3 justify-space-start align-center">
				<h2>Info</h2>
			</v-row>

			<v-form class="mx-8 my-5" @submit.prevent="setStreamInfo">
				<v-row>
					<v-col xl="3" lg="3" md="3" cols="12">
						<v-text-field
							v-model.trim="$v.shorthand.$model"
							:error-messages="S_Errors"
							@input="$v.shorthand.$touch()"
							@blur="$v.shorthand.$touch()"
							label="Points Naming"
							outlined
							clearable
						></v-text-field>
					</v-col>

					<v-col xl="3" lg="3" md="3" cols="12">
						<v-text-field
							v-model.trim="$v.community.$model"
							:error-messages="C_Errors"
							@input="$v.community.$touch()"
							@blur="$v.community.$touch()"
							label="Community Name"
							hint="Eg. bot greeting new follows etc."
							outlined
							clearable
						></v-text-field>
					</v-col>
				</v-row>

				<v-row class="d-flex justify-end">
					<v-col xl="2" class="d-flex justify-end">
						<v-btn color="primary" :loading="loadingBtn" :disabled="loadingBtn" type="submit">Save</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</v-card>
	</v-col>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
	data() {
		return {
			valuesLoading: true,
			loadingBtn: false,
			shorthand: '',
			community: ''
		}
	},

	validations() {
		return {
			shorthand: {
				required,
			},
			community: {
				required,
			},
		};
	},

	computed: {
		S_Errors() {
			const errors = [];
			if (!this.$v.shorthand.$dirty) return errors;
			!this.$v.shorthand.required && errors.push('Points naming is required.');
			return errors;
		},

		C_Errors() {
			const errors = [];
			if (!this.$v.community.$dirty) return errors;
			!this.$v.community.required && errors.push('Community Name is required.');
			return errors;
		},
	},

	async mounted() {
		await this.fetchStreamInfo();
	},

	methods: {
		async updateStreamInfo() {
			await this.fetchStreamInfo();
		},

		async fetchStreamInfo () {
			try {
				const request = await fetch(`${this.$store.state.base_url}/stream_info`, {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});
				const res = await request.json();

				if (request.ok && request.status === 200) {
					if (res.streamInfo) {
						this.shorthand = res.streamInfo.shorthand;
						this.community = res.streamInfo.community;
					}
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message
				});
			}
			finally {
				this.valuesLoading = false;
			}
		},

		async setStreamInfo () {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.loadingBtn = true;

				try {
					const request = await fetch(this.$store.state.base_url + '/stream_info/update', {
						method: 'POST',
						body: JSON.stringify({
							community: this.community,
							shorthand: this.shorthand,
						}),
						headers: {
							"Content-type" : "application/json; charset=UTF-8",
							"Authorization" : this.$cookies.get('mgn_sessid'),
						}
					});
					const res = await request.json();
					
					if (request.ok) {	
						if (res.isSIupdated) {
							await this.fetchStreamInfo();

							this.$notify({
								group: 'main',
								type: 'success',
								title: this.$store.state.app_name,
								text: 'Stream Info updated successfully!'
							});
						}
						else if (res.isValueNotChanged) {
							this.$notify({
								group: 'main',
								type: 'warn',
								title: this.$store.state.app_name,
								text: 'Stream Info is already saved! No changes made.'
							});
						}
						else {
							this.$notify({
								group: 'main',
								type: 'error',
								title: this.$store.state.app_name,
								text: res.error
							});
						}
					}
					else if (request.status === 401) {
						this.sessionExpiredHandler(res);
					}
					else {
						if (res.error) {
							if (this.$store.state.error_types.includes(res.error)) {
								this.sessionExpiredHandler(res);
							}
							else {
								throw res.error;
							}
						}
					}
				}
				catch (error) {
					this.$notify({
						group: 'main',
						type: 'error',
						title: this.$store.state.app_name,
						text: error
					});
				}
				finally {
					this.loadingBtn = false;
				}
			}
		}
	},
}
</script>